import React from 'react'
import { graphql } from 'gatsby'
import { responsiveImage } from '../../utils/helpers.js'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../../components/layout'
import Hero from '../../components/heroSubpage'
import SEO from '../../components/seo'
import AltSection from '../../components/columnAlternating'

export default ({ data, location }) => {
  const post = data.markdownRemark
  const hero = responsiveImage(post.frontmatter.image.childImageSharp.fluid.srcSetWebp)
  
  return (
    <div id="sub-page">
      <Layout>
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + hero}
        />

        <Hero
          class="intro-65"
          image={hero}
          foreimage={null}
          forealt={post.frontmatter.imageAltText}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div className="font-w-400 text-xs-extra-large text-opening-para">
                {post.frontmatter.openingpara}
              </div>

                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && (
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              {post.frontmatter.section.map((financing, index) => {
                return (
                  <div key={index}>
                    <AltSection
                      key={index}
                      title={financing.title}
                      subtitle={financing.subtitle}
                      description={financing.description}
                      image={financing.image.childImageSharp.fluid}
                      imageAltText="An example of a UniGW dashboard, used to digitally transform enterprise applications"
                      placement={financing.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 pt-3 title-xs-medium title-medium text-very-dark-blue"
                      link={financing.link}
                      linktext={financing.linktext}
                      anchor={financing.subtitle.toLowerCase().replace(/ /g, '-')}
                      colour="ficon-digital"
                    />
                  </div>
                )
              })}
            </MDBContainer>
          </section>
        )}
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(
      frontmatter: {
        template: { eq: "other" }
        title: { eq: "Corporate financing" }
      }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        ctatext
        ctaslug         
        openingpara
        alttext
        image {
          childImageSharp {
            fluid(maxWidth: 1980, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        section {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 850, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alttext
          placement
          description
          linktext
          link
        }
      }
      html
    }
  }
`
